// extracted by mini-css-extract-plugin
export var Contact = "x_kt";
export var Contact__title = "x_kv";
export var Contact__subTitle = "x_kw";
export var Contact__form = "x_kx";
export var Contact__item = "x_ky";
export var Contact__itemLeft = "x_kz";
export var Contact__itemRight = "x_kB";
export var Contact__itemRightInput = "x_kC";
export var Contact__itemRightSelect = "x_kD";
export var Contact__option = "x_kF";
export var Contact__itemSelectArrowIcon = "x_kG";
export var Contact__formTextArea = "x_kH";
export var Contact__confirm = "x_kJ";
export var Contact__confirmText = "x_kK";
export var Contact__confirmButton = "x_kL";
export var Contact__confirmPopupModal = "x_kM";
export var Contact__privacyPopup = "x_kN";
export var Contact__privacyPopupCloseButton = "x_kP";
export var ContactConfirmPopup = "x_kQ";
export var ContactConfirmPopup__item = "x_kR";
export var ContactConfirmPopup__itemName = "x_kS";
export var attachment = "x_kT";
export var inputWrap = "x_kV";
export var selectWrap = "x_kW";
export var innerSection = "x_kX";
export var privacyLink = "x_G";
export var send = "x_kY";
export var confirmActions = "x_H";