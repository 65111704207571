import React, { useEffect, useState } from "react";
import { DefaultLayout } from "../organisms/DefaultLayout";
import { SEO } from "../atoms/seo/SEO";
import seoImage from "../images/vision/vision-osaka.jpg";
import ContactTemplate from "../organisms/contact";

export default ({ location: pathname }) => {
  return (
    <DefaultLayout>
      <SEO
        pathname={pathname}
        title="コンタクト | Contact"
        description="スプリーブホールディングス株式会社の事業・採用・取材・講演・イベント等に関するお問い合わせは、こちらからお願いいたします。"
        image={seoImage}
      />
      <ContactTemplate />
    </DefaultLayout>
  );
};
