import React, { FunctionComponent, useEffect, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import { navigate } from "gatsby";
import * as styles from "./index.module.scss";
import * as privacyStyles from "./404.module.scss";
import { SERV_HOST } from "../../atoms/constans";
import { Popup } from "../../atoms/popup/Popup";
import { ChevronRight } from "../../atoms/graphics/ChevronRight";
import { PrivacyPolicyContent } from "../privacy/PrivacyPolicyContent";

// TODO: apolloのURLがベタ書きなので真似てる、とのことなので未来で書き換える
const inquiryUrl = "/inquiry.php";

axios.defaults.baseURL = SERV_HOST;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
// TODO: テスト用。いらなくなったら消す。
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

const categories = [
  { value: "services", label: "事業に関するお問い合わせ" },
  { value: "careers", label: "採用に関するお問い合わせ" },
  { value: "diversity", label: "障がい者採用へのご応募" },
  { value: "events", label: "取材・講演・イベント出演に関するお問い合わせ" },
  { value: "others", label: "その他" },
];

function getCategoryLabel(value: string): string | undefined {
  return categories.find(category => category.value === value)?.label;
}

const ContactTemplate: FunctionComponent = () => {
  const [isDisplayedModal, setIsDisplayedModal] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  /** 性 */
  const [familyName, setFamilyName] = useState("");
  /** 名前 */
  const [firstName, setFirstName] = useState("");
  /** 会社名/学校名 */
  const [profile, setProfile] = useState("");
  /** メールアドレス */
  const [email, setEmail] = useState("");
  /** 電話番号 */
  const [phone, setPhone] = useState("");
  /** お問い合わせ内容種類 */
  const [category, setCategory] = useState("");
  /** お問い合わせ内容 */
  const [body, setBody] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setCategory(params.get("type") || "");
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsConfirming(true);
    return false;
  };

  const submit = async () => {
    try {
      const cat = categories.find(row => row.value === category);
      await axios.post(inquiryUrl, {
        familyName,
        firstName,
        email,
        tel: phone,
        text: body,
        category: cat?.label,
      });
      await navigate("/thanks");
    } catch (err) {
      switch (err.response?.status) {
        case 422:
          // console.log(err.response.data);
          break;
        default:
          console.error(err);
          break;
      }
    }
  };

  return (
    <div className={styles.Contact}>
      <div>
        <h2 className={styles.Contact__title}>Contact</h2>
        <h3 className={styles.Contact__subTitle}>お問い合わせ</h3>
      </div>

      <div className={styles.Contact__form}>
        <form onSubmit={handleSubmit}>
          <ul className={styles.Contact__items}>
            <li className={styles.Contact__item}>
              <p className={styles.Contact__itemLeft}>お名前(必須)</p>
              <div className={styles.Contact__itemRight}>
                <input
                  placeholder={"姓"}
                  value={familyName}
                  onChange={e => setFamilyName(e.target.value)}
                  required={true}
                  className={styles.Contact__itemRightInput}
                />
                <input
                  placeholder={"名"}
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required={true}
                  className={styles.Contact__itemRightInput}
                />
              </div>
            </li>

            <li className={styles.Contact__item}>
              <p className={styles.Contact__itemLeft}>会社名/学校名</p>
              <div className={styles.Contact__itemRight}>
                <input
                  placeholder={"例:株式会社XXXX"}
                  value={profile}
                  onChange={e => setProfile(e.target.value)}
                  className={styles.Contact__itemRightInput}
                />
              </div>
            </li>

            <li className={styles.Contact__item}>
              <p className={styles.Contact__itemLeft}>メールアドレス（必須）</p>
              <div className={styles.Contact__itemRight}>
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required={true}
                  className={styles.Contact__itemRightInput}
                />
              </div>
            </li>

            <li className={styles.Contact__item}>
              <p className={styles.Contact__itemLeft}>ご連絡可能な電話番号</p>
              <div className={styles.Contact__itemRight}>
                <input
                  type="tel"
                  placeholder={"※任意"}
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  className={styles.Contact__itemRightInput}
                />
              </div>
            </li>

            <li className={styles.Contact__item}>
              <p className={styles.Contact__itemLeft}>
                お問い合わせ内容（必須）
              </p>
              <div className={styles.Contact__itemRight}>
                <select
                  onChange={e => setCategory(e.target.value)}
                  value={category}
                  required={true}
                  className={styles.Contact__itemRightSelect}
                >
                  <option disabled value="" className={styles.Contact__option}>
                    選択してください
                  </option>
                  {categories.map(({ label, value }, i) => (
                    <option key={`contact-option-${i}`} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </li>
            <li className={styles.Contact__itemSelectArrowIcon}>
              <ChevronRight color="#888888" />
            </li>
          </ul>

          <textarea
            placeholder={"お問い合わせ内容（最大4000文字まで）"}
            maxLength={4000}
            value={body}
            onChange={e => setBody(e.target.value)}
            className={styles.Contact__formTextArea}
          />

          {"3" === category && (
            <div className={styles.attachment}>
              <p>
                応募書類の添付（jpg、jpeg、pdf、5MBまで/複数選択可）
                <input type="file" multiple={true} />
              </p>
            </div>
          )}

          <div className={styles.Contact__confirm}>
            <p className={styles.Contact__confirmText}>
              お問い合わせには、
              <span
                className={styles.privacyLink}
                onClick={() => setIsDisplayedModal(true)}
              >
                プライバシーポリシー
              </span>
              へ同意が必要です
            </p>
            <input
              type="submit"
              value="同意の上、入力内容を確認"
              className={styles.Contact__confirmButton}
            />
          </div>
        </form>
      </div>

      <Popup
        open={isConfirming}
        onClose={() => setIsConfirming(false)}
        footer={
          <div className={styles.confirmActions}>
            <button onClick={submit}>送信</button>
          </div>
        }
        className={styles.Contact__confirmPopup}
        modalClassName={styles.Contact__confirmPopupModal}
      >
        <div className={styles.Contact__privacyPopupCloseButton}>
          <button onClick={e => setIsConfirming(false)}>×</button>
        </div>
        <div className={styles.ContactConfirmPopup}>
          <div className={styles.ContactConfirmPopup__item}>
            <p className={styles.ContactConfirmPopup__itemName}>お名前(必須)</p>
            <span className="flex-1">{familyName}</span>
            <span className="flex-1">{firstName}</span>
          </div>

          <div className={styles.ContactConfirmPopup__item}>
            <p className={styles.ContactConfirmPopup__itemName}>
              会社名／学校名
            </p>
            {profile}
          </div>

          <div className={styles.ContactConfirmPopup__item}>
            <p className={styles.ContactConfirmPopup__itemName}>
              メールアドレス（必須）
            </p>
            {email}
          </div>

          <div className={styles.ContactConfirmPopup__item}>
            <p className={styles.ContactConfirmPopup__itemName}>
              ご連絡可能な電話番号
            </p>
            {phone}
          </div>

          <div className={styles.ContactConfirmPopup__item}>
            <p className={styles.ContactConfirmPopup__itemName}>
              お問い合わせ内容(必須)
            </p>
            {getCategoryLabel(category)}
          </div>
          {"3" === category && (
            <div className={styles.attachment}>
              <p>応募書類の添付（jpg、jpeg、pdf、5MBまで/複数選択可）</p>
              <input type="file" multiple={true} />
            </div>
          )}
          <div className={styles.ContactConfirmPopup__item}>{body}</div>
        </div>
      </Popup>

      <Popup
        open={isDisplayedModal}
        onClose={() => setIsDisplayedModal(false)}
        modalClassName={styles.Contact__privacyPopup}
      >
        <div className={styles.Contact__privacyPopupCloseButton}>
          <button onClick={e => setIsConfirming(false)}>×</button>
        </div>
        <div
          className={classNames(
            privacyStyles.privacyContent,
            styles.ContactPrivacyPopup
          )}
        >
          <PrivacyPolicyContent />
        </div>
      </Popup>
    </div>
  );
};

export default ContactTemplate;
